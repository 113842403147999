import Link from 'next/link';
import { Menu } from 'antd';
import FaIcon from './shared/icon';
import { useP21UserContext } from '../context/p21-user-context';

const { SubMenu } = Menu;

export const HeaderMenu = ({ graphData }) => {
  const { permissions } = useP21UserContext();
  const getTitle = (title, icon) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {icon && <FaIcon icon={icon} />}
        {title}
      </div>
    );
  };

  const renderMenu = () => {
    const menuLinks = [
      {
        key: 'customers',
        name: 'Customers',
        icon: 'group',
        subMenu: [
          {
            key: 'order-entry',
            name: 'Order Entry',
            link: '/order-entry',
          },
          {
            key: 'credit-apps',
            name: 'Credit Applications',
            link: '/credit-applications-v2',
          },
          {
            key: 'customer-page',
            name: 'Customer',
            link: '/customer',
          },
          {
            key: 'error-logging',
            name: 'Error Entry',
            link: '/error-logging',
          },
        ],
      },
      {
        key: 'm-bot-main',
        name: 'M-Bot',
        icon: 'local_shipping',
        subMenu: [
          { key: 'm-bot', name: 'M-Bot', link: '/m-bot-v2' },
          {
            key: 'enter-pro',
            name: 'Manage PRO Numbers',
            link: '/manage-pro-numbers',
          },
        ],
      },
      {
        key: 'marketing',
        name: 'Marketing',
        icon: 'view_module',
        subMenu: [
          {
            key: 'manage-design-order',
            name: 'Manage Design Order',
            link: '/marketing/design-order/manage',
          },
          {
            key: 'create-design-order',
            name: 'Create Design Order',
            link: '/marketing/design-order/create',
          },
          {
            key: 'create-photo-request',
            name: 'Create Photo Request',
            link: '/marketing/photo-request/create',
          },
          {
            key: 'asset-management',
            name: 'Product Asset Management',
            link: '/marketing/product-asset-management',
          },
        ],
      },
    ];

    if (permissions.isSupplyChain) {
      menuLinks.push({
        key: 'supply-chain',
        name: 'Supply Chain',
        icon: 'inventory',
        subMenu: [
          {
            key: 'inventory-details',
            name: 'Inventory Allocation',
            link: '/inventory-management',
          },
          {
            key: 'create-new-item',
            name: 'Create/Edit Item',
            link: '/create-new-item',
          },
        ],
      });
    }

    menuLinks.push({
      key: 'product',
      name: 'Product',
      icon: 'local_offer',
      subMenu: [
        { key: 'item-search', name: 'Item Search', link: '/item-search' },
        {
          key: 'item-details',
          name: 'Item Details',
          link: '/item',
        },
        {
          key: 'vendor-supplier',
          name: 'Vendor Supplier',
          link: '/vendor-supplier',
        },
      ],
    });

    return menuLinks.map((link) => {
      return link.subMenu ? (
        <SubMenu key={link.key} title={getTitle(link.name, link.icon)}>
          {link.subMenu.map((subLink) => {
            return (
              <Menu.Item key={subLink.key}>
                <Link href={subLink.link}>{subLink.name}</Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      ) : (
        <Menu.Item key={link.key}>{getTitle(link.name, link.icon)}</Menu.Item>
      );
    });
  };

  return (
    <Menu
      theme='dark'
      mode='horizontal'
      style={{ width: '100%', flex: 'auto' }}
    >
      {renderMenu()}
    </Menu>
  );
};
